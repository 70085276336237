import React from 'react';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { materialTheme } from './theme/theme';
import { datadogRum } from '@datadog/browser-rum';
import getEnv from './env';
import packageJson from '../package.json';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const { version } = packageJson;
rootElement.setAttribute('version', version.substring(1));
const root = createRoot(rootElement);
const queryClient = new QueryClient();

datadogRum.init({
  applicationId: getEnv().react_app_dd_app_id,
  clientToken: getEnv().react_app_dd_client_id,
  site: 'datadoghq.com',
  service: 'landlord-portal-frontend',
  env: getEnv().app_env,
  // Specify a version number to identify the deployed version of your application in Datadog
  version,
  sessionSampleRate: 10,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={materialTheme}>
          <App />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
