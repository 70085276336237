import { Divider, List, Box } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { SideMenuItem } from './SideMenuItem';
import { MenuContent } from './MenuContent';
import useMenu from './useMenu';

export const SideMenu = () => {
  const { menuOpen, openMenu, closeMenu } = useMenu();

  const toggleMenu = () => {
    if (menuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  return (
    <Box
      id="sideMenu"
      sx={{
        backgroundColor: 'primary.light',
        position: 'fixed',
        boxShadow: '0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
        top: '120px', // 80px (height of header) + 40px of paddings
        left: '20px',
        bottom: '100px',
        borderRadius: '8px',
        display: { xs: 'none', md: 'block' },
      }}
    >
      <List sx={{ height: '100%', padding: 0, display: 'flex', flexDirection: 'column' }}>
        <SideMenuItem
          open={menuOpen}
          ariaLabel="toggle side menu"
          icon={menuOpen ? <ChevronLeft /> : <ChevronRight />}
          action={toggleMenu}
        />

        <Divider />

        <MenuContent menuOpen={menuOpen} />
      </List>
    </Box>
  );
};
