import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material';

import { createUrlWithUTM } from '../utils/linkUtils';

type LinkPropsVariants = { href: string } | { onClick: React.MouseEventHandler<HTMLAnchorElement> };

type LinkProps = Omit<MUILinkProps, 'href' | 'onClick'> & LinkPropsVariants;

export const Link = (props: LinkProps) => {
  if ('onClick' in props) {
    return <MUILink {...props} />;
  }

  try {
    const href = createUrlWithUTM(props.href);

    return <MUILink {...props} href={href.toString()} />;
  } catch (_e) {
    // Url is relative
    return <MUILink {...props} />;
  }
};
