import { ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  open: boolean;
  text?: string;
  action: () => void;
  icon?: JSX.Element;
  ariaLabel?: string;
  selected?: boolean;
  disabled?: boolean;
}

export const SideMenuItem = ({
  open,
  text,
  action,
  icon,
  ariaLabel,
  selected,
  disabled,
}: Props) => (
  <ListItem
    disablePadding
    sx={{
      width: { xs: '100%', md: open ? '250px' : '60px' },
      transition: 'width 0.3s linear',
    }}
  >
    <Tooltip arrow title={text} disableHoverListener={open || !text} placement="right">
      <ListItemButton
        onClick={action}
        className={open ? 'list-item-button-open' : 'list-item-button-close'}
        disabled={disabled}
        sx={{
          justifyContent: text || !open ? 'flex-start' : 'flex-end',
          height: '50px',
          backgroundColor: selected ? 'grey.50' : undefined,
          [':hover']: {
            backgroundColor: 'secondary.light',
          },
        }}
        aria-label={ariaLabel ?? text}
      >
        <ListItemIcon
          sx={{
            justifyContent: text || !open ? 'flex-start' : 'flex-end',
          }}
        >
          {icon}
        </ListItemIcon>
        {text ? (
          <ListItemText
            primary={text}
            primaryTypographyProps={{ color: disabled ? 'text.disabled' : undefined }}
            sx={{
              opacity: open ? '1' : '0',
              width: open ? '160px' : '0',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              transition: 'all 0.3s linear',
            }}
          />
        ) : null}
      </ListItemButton>
    </Tooltip>
  </ListItem>
);
