import { apiUri } from '../../constants/Constants';
import { client } from '../axios/axiosClient';

export type featureFlagData = {
  data: featureFlagValues;
};

export type featureFlagValues = {
  test: boolean;
  enable_company_phone_for_smb: boolean;
  is_new_smb_portfolio_amount: boolean;
  enable_applications_tracking_view: boolean;
  enable_old_size_of_portfolio: boolean;
  enable_new_renter_invites_for_smb: boolean;
};

const getFeatureFlags = async () => {
  return await client.get(apiUri.getFeatureFlags).then((value: featureFlagData) => {
    return value.data;
  });
};

export default getFeatureFlags;
