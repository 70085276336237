import React from 'react';
import { memo } from 'react';
import { Routes, Route } from 'react-router';
import WithSuspense from './WithSuspense';
import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';

type routeParams = {
  path: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
  role?: string;
  redirect?: string;
};

export const allRoutes = [...PublicRoutes, ...PrivateRoutes].map((route: routeParams) => {
  const Component = WithSuspense(route.component);
  function WrapperComponent() {
    return <Component />;
  }

  return {
    ...route,
    component: memo(WrapperComponent),
  };
});

function GlobalRouter() {
  return (
    <Routes>
      {allRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={<route.component />} />
      ))}
    </Routes>
  );
}

export default GlobalRouter;
