import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import getFeatureFlags, { featureFlagValues } from '../utils/apiRestCalls/getFeatureFlags';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

const initialfeatureFlagValues = {
  test: false,
  enable_company_phone_for_smb: false,
  is_new_smb_portfolio_amount: false,
  enable_applications_tracking_view: false,
  enable_old_size_of_portfolio: true,
  enable_new_renter_invites_for_smb: true,
};

const featureflagStore = create(
  devtools<{
    featureFlags: featureFlagValues;
    fetchFeatureFlags: () => Promise<void>;
  }>((set) => ({
    featureFlags: initialfeatureFlagValues,
    fetchFeatureFlags: async () => {
      const { data: featureFlags } = useQuery({
        queryKey: ['featureFlag'],
        queryFn: () => getFeatureFlags(),
        staleTime: Infinity,
      });
      useEffect(() => {
        if (featureFlags) {
          set({ featureFlags: featureFlags });
        }
      }, [featureFlags]);
    },
  })),
);

export default featureflagStore;
