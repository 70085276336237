import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import X from './../assets/images/X.svg';
import getEnv from '../env';

const env = getEnv();

export const routes = {
  // public routes
  brokerInvite: '/broker-signup',
  numberUnitsOwned: '/units-owned',
  registerEmail: '/email-registration',
  checkEmail: '/email-confirmation',
  emailSuccess: '/email-finish',
  error500: '/*',
  limitation: '/limitation',
  thankYou: '/thank-you',

  // private routes
  home: '/',
  test: '/test',
  propertiesTable: '/property-table',
  enroll: '/enroll',
  propertyEnrollmentForm: '/enroll/property-form',
  propertyEnrollConfirm: '/enroll/confirmation',
  enrollNotEligible: '/enroll-not-eligible',
  myProperties: '/my-properties',
  unitsOwnedEnroll: '/units-owned-enroll',
  renterInvite: '/renter-invite',
  renterInviteConfirmation: '/renter-invite/confirmation',
  accountUsersList: '/account/users-list',
  applicationsDashboard: '/applications',
};

export const apiUri = {
  //private
  getMe: 'v1/me',
  addProperty: 'v1/properties/add',
  updateUserInfo: 'v1/me/update',
  updateUserStatus: 'v1/me/update-status',
  propertiesList: 'v1/properties/list',
  buildingsList: 'v1/buildings',
  inviteRenter: 'v1/renter/invite',

  accountUsersList: 'v1/account/users',
  accountDisableUser: 'v1/account/users/{userId}/disable',
  accountEnableUser: 'v1/account/users/{userId}/enable',

  userInvite: 'v1/account/users/invite',
  resendInvite: 'v1/account/users/resend-invite',
  userEdit: 'v1/account/users/{userId}',
  applicationsList: 'v1/deals/{landlordId}',

  // public
  register: 'public/me/register',
  registerBroker: 'public/me/broker-signup',
  resendEmail: 'public/me/resend-verification-email',
  getFeatureFlags: 'public/feature-flags',
  getABTests: 'public/ab-tests/list/',
  sendExperiment: 'public/ab-tests/send',
};

export const externalConnections = {
  dashboard: env.legacyLLPEndpoint,
  invite: `${env.legacyLLPEndpoint}/invite`,
  signin: env.login + 'sign-in',
  logout: env.login + 'logout',
  refreshTokenUrl: env.login_api + 'v1/token/refresh',
  loginApiLogout: env.login_api + 'v1/logout',
};

const hubspot = env.hubspot_form_api_url;
const hubspotPortal = env.hubspot_portal_id;
export const hubspotSMBURL = hubspot + `${hubspotPortal}/${env.hubspot_smb_id}`;
export const hubspotEnterpriseURL = hubspot + `${hubspotPortal}/${env.hubspot_enterprise_id}`;
export const hubspotBrokerURL = hubspot + `${hubspotPortal}/${env.hubspot_broker_id}`;

export const HOSTNAME = 'theguarantors.com';

export const externalLinks = {
  accountCreationAccessTerms:
    'https://www.theguarantors.com/account-creation-access-terms?hsLang=en',
  privacy: 'https://www.theguarantors.com/privacy?hsLang=en',
  terms: 'https://www.theguarantors.com/terms?hsLang=en',
  legalRegulatory: 'https://www.theguarantors.com/legal-regulatory?hsLang=en',
  landLordhelpCenter: 'https://www.theguarantors.com/landlords-help-center',
  helpEmail: 'landlord@theguarantors.com', // ignore this
  faq: 'https://www.theguarantors.com/enroll-property#faq',
  operatorsFAQ: 'https://www.theguarantors.com/faq-for-operators',
  renterFAQ: 'https://www.theguarantors.com/renter-invite#faq',
  noticeCollection: 'https://www.theguarantors.com/notice-of-collection',
  gettingStarted: 'https://www.theguarantors.com/get-started',
  submitClaim: 'https://www.theguarantors.com/submit-a-claim',
  LandingPageSMB: 'https://www.theguarantors.com/enroll-property', // SMB Landing page
  homePage: 'https://www.theguarantors.com', // TG Landing page
  ownersAndOperators: 'https://www.theguarantors.com/operators',
  ownersContactPage: 'https://www.theguarantors.com/contact-us',
  gettingStartedFlow: 'https://www.theguarantors.com/get-started/operators/step-1',
  newSmbUserLearnMore: 'https://www.theguarantors.com/landlords/help-center/enroll-a-property',
};

export const externalTitles = {
  privacy: 'Privacy Policy',
  terms: 'Terms of Service',
  legalRegulatory: 'Legal/Regulatory Notice',
  helpEmail: 'Help Email',
  faq: 'FAQ',
  noticeCollection: 'Notice of Collection',
};

export const footerMenu = [
  {
    title: externalTitles.privacy,
    to: externalLinks.privacy,
  },

  {
    title: externalTitles.terms,
    to: externalLinks.terms,
  },

  {
    title: externalTitles.legalRegulatory,
    to: externalLinks.legalRegulatory,
  },
  {
    title: externalTitles.noticeCollection,
    to: externalLinks.noticeCollection,
  },
];

export const SocialLinks = [
  {
    icon: <LinkedInIcon aria-label="LinkedIn" titleAccess="LinkedIn" aria-hidden="false" />,
    link: 'https://www.linkedin.com/company/the-guarantors',
  },
  {
    icon: <img src={X} alt="X" height="18px" width="18px" style={{ paddingBottom: '3px' }} />,
    link: 'https://twitter.com/The_Guarantors',
  },
  {
    icon: <FacebookIcon aria-label="Facebook" titleAccess="Facebook" aria-hidden="false" />,
    link: 'https://www.facebook.com/TheGuarantors',
  },
  {
    icon: <InstagramIcon aria-label="Instagram" titleAccess="Instagram" aria-hidden="false" />,
    link: 'https://www.instagram.com/theguarantors',
  },
];

export const text = [
  {
    header: 'Approved',
    text: 'This renter is low risk (good credit profile, solid rental history, etc). Selecting “approved” for this type of applicant will invite them to purchase a one month security deposit replacement.',
  },
  {
    header: 'Conditional',
    text: 'This renter is medium risk (perhaps due to a credit dip, unstable income, etc). Selecting “conditionally approved” for this type of applicant will invite them to purchase a policy for five months of lease coverage and a one month security deposit replacement.',
  },
  {
    header: 'Denied',
    text: 'This renter is high risk (may nave no credit, limited income, etc). Selecting “denied” for this type of applicant will invite them to purchase a policy for eleven months of lease coverage and a one month security deposit replacement.',
  },
];

export const UTM_CODES = [
  'utm_adgroup',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
] as const;

export type UserStatus = {
  isNewSelfServiceUser: boolean;
  hasProperties: boolean;
  isAccountAdmin: boolean;
  newSMBNotification?: string;
};
