import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getABTests } from '../utils/apiRestCalls/ABTests';

// experiments configuration should match Evidently config on AWC console
export const Experiments = {
  // unitsOwnedRemovedFeature: {
  //   name: 'units-owned-removed-feature',
  //   variations: {
  //     withUnitsOwned: 'v1',
  //     withoutUnitsOwned: 'v2',
  //   },
  //   metrics: {
  //     successRate: 'success-rate',
  //   },
  // },
  // setPasswordAfterRegistrationFeature: {
  //   name: 'set-password-after-registration-feature',
  //   variations: {
  //     verifyEmail: 'verify-email',
  //     setPassword: 'set-password',
  //   },
  //   metrics: {
  //     sawRegisterPage: 'saw-register-page',
  //   },
  // },
  collectPhoneCompanyForSMB: {
    name: 'collect-phone-company-for-smb-feature',
    variations: {
      withPhoneCompany: 'v1',
      withoutPhoneCompany: 'v2',
    },
    metrics: {
      successRate: 'marketingPhoneCompanySMB',
    },
  },
} as const;

export interface Experiment {
  entityId: string;
  featureName: string;
  result: string;
}

export interface ABTests {
  [key: string]: Experiment;
}

export interface ABTestsValues {
  [key: string]: string;
}

const initialABTestsValues = {};

const ABTestStore = create(
  devtools<{
    ABTests: ABTests;
    ABTestsValues: ABTestsValues;
    loaded: boolean;
    fetchABTestsValues: () => Promise<void>;
  }>((set) => ({
    ABTests: initialABTestsValues,
    ABTestsValues: {},
    loaded: false,
    fetchABTestsValues: async () => {
      const { data: ABTests } = useQuery({
        queryKey: ['ABTests'],
        queryFn: () => getABTests(),
        staleTime: Infinity,
      });
      useEffect(() => {
        if (ABTests) {
          const newValues: { [key: string]: string } = {};
          Object.keys(ABTests).forEach((key) => {
            newValues[key] = ABTests[key].result;
          });
          set({ ABTests, ABTestsValues: newValues, loaded: true });
        }
      }, [ABTests]);
    },
  })),
);

export default ABTestStore;
