import { useTheme, Box, Divider, Typography } from '@mui/material';
import Logo from './../assets/images/footer-logo.png';
import { externalLinks, footerMenu, SocialLinks } from '../constants/Constants';
import Wechat from './../assets/images/Wechat.svg';
import { goToExternal } from '../utils/linkUtils';
import { Link } from '../components/Link';

const SiteFooterPublic = () => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          px: { xs: 2.5, lg: 7.5 },
          py: { xs: 5, lg: 10 },
          mt: { xs: '48px' },
        }}
      >
        <Box
          component="img"
          onClick={() => {
            goToExternal(externalLinks.homePage, {
              title: 'Go to Main Page',
              path: '/home',
            });
          }}
          sx={{
            [theme.breakpoints.down('lg')]: {
              width: '315px',
              height: '40px',
            },
            width: '700px',
            height: '90px',
            cursor: 'pointer',
          }}
          src={Logo}
          alt="TheGuarantors"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            gap: '15px',
            marginTop: { xs: '50px', sm: '0px' },
          }}
        >
          {SocialLinks.map((item, index) => (
            <Link
              key={index}
              target="_blank"
              href={item.link}
              underline="hover"
              color="primary.light"
              role="footerLinks"
            >
              {item.icon}
            </Link>
          ))}
          <Link
            target="_blank"
            href={'https://www.theguarantors.com/wechat'}
            underline="hover"
            color="primary.light"
            role="footerLinks"
          >
            <img src={Wechat} alt="wechat" height="24px" width="24px" />
          </Link>
        </Box>
      </Box>
      <Divider sx={{ opacity: 0.5, border: `0.5px solid ${theme.palette.primary.light}` }} />

      <Box sx={{ px: { xs: 2.5, md: 7.5 } }}>
        <Box pt="25px">
          <Typography
            variant="body1"
            color="grey.200"
            sx={{
              maxWidth: { xs: '300px', sm: '650px' },
            }}
          >
            TheGuarantors is a licensed insurance agent and broker. For more detailed information,
            please see our{' '}
            <Link
              underline="always"
              data-variant="noColor"
              target="_blank"
              sx={{
                textDecorationColor: 'unset',
                ':hover': { color: 'primary.light' },
              }}
              href={externalLinks.legalRegulatory}
            >
              Legal/Regulatory Notice
            </Link>
            .
          </Typography>
        </Box>
        <Box py="30px">
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
              flexDirection: { lg: 'row-reverse' },
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: { xs: theme.spacing(2), md: theme.spacing(5, 2), lg: 5 },
                flexWrap: 'wrap',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            >
              {footerMenu.map((item, index) => (
                <Link
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      width: '45%',
                    },
                    ':hover': { color: 'primary.light' },
                  }}
                  key={index}
                  variant="body1"
                  underline="hover"
                  color="grey.200"
                  target="_blank"
                  href={item.to}
                >
                  {item.title}
                </Link>
              ))}
            </Box>
            <Typography variant="body1" color="grey.200">
              © {currentYear} TheGuarantors
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SiteFooterPublic;
